Drupal.behaviors.udesk = {
  attach: () => {
    /* eslint no-magic-numbers: [1, { "ignoreArrayIndexes": true }] */
    const chatLinks = '.js-whoson-chat-link, .support_live_chat, .chat_now_h';
    const button = '.livechat_button';
    const host = window.location.href.split('/')[2];
    const hash = window.location.hash;
    const endOfLine = String.fromCharCode('10');
    var params;
    var prod;    
    var skuItem;
    var text;
    var shaded;
    var skuName;
    var data;
    var udLoaded = 0;
    // eslint-disable-next-line camelcase
    var pageData = page_data;

    $(chatLinks + ', ' + button).removeAttr('href').css('cursor', 'pointer');

    // common definition:
    params = {
      'code': 'hifae60',
      'link': 'https://bk.s4.udesk.cn/im_client/?web_plugin_id=17990',
      'selector': chatLinks,
      'targetSelector': button,
    };

    //button style
    /* eslint-disable id-length */
    params.css = {
      'max-width': '260px',
    };
    params.mobile = {
      'css': {
        'width': '91px',
        'height': '91px',
      }
    };

    //chat window style
    params.panel = {
      'css': {
        'width': '420px',
        'right': '0',
      }
    };
    /* eslint-enable id-length */

    if (pageData && pageData['catalog-spp'] && pageData['catalog-spp'].products && pageData['catalog-spp'].products.length) {
      prod = pageData['catalog-spp'].products[0];
      text = [prod.SUB_LINE, prod.PROD_RGN_NAME].join(endOfLine).replace(/<br\s*[/]?>/giu, endOfLine).replace(/(<([^>]+)>)/igu, '');
      shaded = prod.shaded;
      params.product = {
        title: text,
        // eslint-disable-next-line id-length
        url: '//' + host + prod.url + hash,
        image: '//' + host + prod.THUMBNAIL_IMAGE,
      };
      if (prod.skus && prod.skus.length) {
        for (skuItem of prod.skus) {
          // eslint-disable-next-line max-len
          skuName = shaded ? skuItem.SHADENAME : skuItem.PRODUCT_SIZE || skuItem.PRODUCT_CODE;
          params.product[skuName] = skuItem.formattedPrice;
        }
      }
    }

    /* global generic */
    generic.jsonrpc.fetch({
      method: 'user.udToken',
      onSuccess: (jsonRpcResponse) => {
        data = jsonRpcResponse.getValue();
        if (data.signature) {
          params.customer = data;
        }
      }
    });

    $(document).ajaxComplete(() => {
      // eslint-disable-next-line camelcase
      if (udLoaded || !params.customer || !params.customer.web_token) {
        return;
      }

      // Load udesk
      /* eslint-disable no-param-reassign, id-length */
      ((win, doc, tag, url, method, obj) => {
        win.UdeskApiObject = method;
        win[method] = win[method] || ((... args) => {
          (win[method].d = win[method].d || []).push(args);
        });
        obj = doc.createElement(tag);
        obj.async = 1;
        obj.charset = 'utf-8';
        obj.src = url;
        tag = doc.getElementsByTagName(tag)[0];
        tag.parentNode.insertBefore(obj, tag);
      })(window, document, 'script', 'https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js', 'ud');
      /* eslint-enable no-param-reassign, id-length */

      /* global ud */
      if (typeof ud !== 'function') {
        return;
      }

      // init chat
      ud(params);
      udLoaded++;
    });
  }
};
